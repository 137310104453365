import {ADMIN_MENU} from '../actions/types';

const initialState = {
    isOpen: JSON.parse(localStorage.getItem('admin-menu-open')) || false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ADMIN_MENU.TOGGLE_MENU:
            return {
                ...state,
                isOpen: !state.isOpen
            };

        default: {
            return state;
        }
    }
}
import {USER} from '../actions/types';

const initialState = {
    token: null,
    userName: null,

    userData: null,
    userDataLoading: false,
    reloadUserData: false,
    userDataError: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case USER.LOGGED_IN:
            return {
                ...state,
                token: action.payload.token,
                userName: action.payload.userName
            };

        case USER.FETCH_USER_DATA_START:
            return {
                ...state,
                userDataLoading: true
            };

        case USER.FETCH_USER_DATA_SUCCESS:
            return {
                ...state,
                userDataLoading: false,
                userData: action.payload.user
            };

        case USER.FETCH_USER_DATA_ERROR:
            return {
                ...state,
                userDataLoading: false,
                userData: undefined,
                userDataError: action.payload.error
            };

        case USER.UPDATE_USER_DATA_START:
            return {
                ...state,
                userData: null,
                reloadUserData: true
            };

        case USER.UPDATE_USER_DATA:
            return {
                ...state,
                userData: action.payload.user,
                reloadUserData: false
            };

        default: {
            return state;
        }
    }
}
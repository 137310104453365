import {CHALLENGES} from '../actions/types';

const initialState = {
    challenges: null,
    challengesLoading: false,
    challengesError: false,
    total:1,
    page:1
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CHALLENGES.FETCH_CHALLENGES_START:
            return {
                ...state,
                challengesLoading: true
            };

        case CHALLENGES.FETCH_CHALLENGES_SUCCESS:
            return {
                ...state,
                challengesLoading: false,
                challenges: action.payload.challenges,
                total:action.payload.totalPages,
                page:action.payload.page
            };

        case CHALLENGES.FETCH_CHALLENGES_ERROR:
            return {
                ...state,
                challengesLoading: false,
                challenges: undefined,
                challengesError: action.payload.error
            };

        default: {
            return state;
        }
    }
}
import {combineReducers} from "redux";
import userReducer from './userReducer';
import adminMenuReducer from "./adminMenuReducer";
import categoriesReducer from "./categoriesReducer";
import challengesReducer from "./challengesReducer";
import subscriptionReducer from "./subscriptionReducer";

export default combineReducers({
    user: userReducer,
    categories: categoriesReducer,
    challenges: challengesReducer,
    adminMenu: adminMenuReducer,
    subscription: subscriptionReducer
});
import React from 'react';
import {connect} from 'react-redux';
import {notificationError} from "../../modules/utils/Notification/Notification";
import Spinner from "../../modules/utils/Spinner/Spinner";
import * as actions from '../actions';

export default Component => {
    const mapStateToProps = state => {
        return {
            reloadUserData: state.user.reloadUserData,
            userDataLoading: state.user.userDataLoading,
            userData: state.user.userData
        };
    };

    const mapDispatchToProps = dispatch => {
        return {
            onFetchUserData: () => dispatch(actions.fetchUserData())
        };
    };

    class Wrapper extends React.Component {
        componentDidMount() {
            if (this.props.userData || this.props.userDataLoading || this.reloadUserData) {
                return;
            }
            this.props.onFetchUserData().catch(err => notificationError(err.message));
        }

        render() {
            if (this.props.userData === null || this.props.userDataLoading || this.reloadUserData) {
                return <Spinner/>;
            }
            return (
                <Component {...this.props} />
            );
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(Wrapper);
};

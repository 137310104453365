import './CookieConsentContent.css';

import React from 'react';

export default class CookieConsentContent extends React.Component {

    render() {
        return (
            <div className='cookie-consent-content'>
                <div>
                    We use cookies to provide you with a better online experience and for statistics and analytics in
                    connection with your use of our service. By clicking “Accept”, you agree to such purposes. You can
                    find out more and manage your consent by viewing our <a href='/#/cookie-policy'>cookies policy</a>.
                </div>
            </div>
        );
    }

}
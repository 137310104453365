import React from 'react';
import styles from './MobileScreen.module.less';

const MobileScreen = () => {
  
  return (
    <div className={styles.mainContainer}>
      <div className={styles.warningContainer}>
        <img className={styles.warningIcon} src="/icons/warning.png" alt=""/>
        <div className={styles.warningText}>Resolution too low</div>
      </div>
      <div className={styles.textContainer}>
        For a more seamless reflection experience on your phone or other device make sure to download our new app here!
      </div>
      <div className={styles.storeLinksContainer}>
        <div className={styles.storeLinkApple}>
          <a href="https://www.apple.com/app-store/">
            <img src='/icons/apple-app-store-logo.png' alt=""/>
          </a>
        </div>
        <div className={styles.storeLinkGoogle}>
          <a href="https://play.google.com/store/apps">
           <img src='/icons/google-play-badge.png' alt=""/>
          </a>
        </div>
      </div>
    </div>
  )
}

export default MobileScreen;
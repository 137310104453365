import Api from "../../modules/api/Api";
import {createAction, USER} from './types';

export const userLoggedIn = (userName, token) => dispatch => {
    dispatch(createAction(USER.LOGGED_IN, {userName, token}));
}

export const fetchUserData = () => dispatch => {
    dispatch(createAction(USER.FETCH_USER_DATA_START));

    return Api.getCurrentUser()
        .then(user => {
            dispatch(
                createAction(USER.FETCH_USER_DATA_SUCCESS, {
                    user: user
                })
            );
        })
        .catch(error => {
            dispatch(createAction(USER.FETCH_USER_DATA_ERROR, {
                error: error
            }))
        });
};
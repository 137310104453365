import { CropDinSharp } from "@material-ui/icons";
import Api from "../../modules/api/Api";
import { createAction, CHALLENGES } from "./types";

export const fetchChallenges =
  (page = 1) =>
  (dispatch) => {
    dispatch(createAction(CHALLENGES.FETCH_CHALLENGES_START));

    return Api.getChallenges(page)
      .then((challenges) => {
        dispatch(
          createAction(CHALLENGES.FETCH_CHALLENGES_SUCCESS, {
            challenges: challenges,
            page:challenges.page
          })
        );
      })
      .catch((error) => {
        dispatch(
          createAction(CHALLENGES.FETCH_CHALLENGES_ERROR, {
            error: error,
          })
        );
      });
  };

import {SUBSCRIPTION} from '../actions/types';

const initialState = {
    loading: false,
    subscribed: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SUBSCRIPTION.FETCH_SUBSCRIPTION_START:
            return {
                ...state,
                loading: true
            };

        case SUBSCRIPTION.FETCH_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                loading: false,
                subscribed: action.payload.subscribed
            };

        case SUBSCRIPTION.FETCH_SUBSCRIPTION_ERROR:
            return {
                ...state,
                loading: false,
                subscribed: action.payload.subscribed
            };

        default: {
            return state;
        }
    }
}
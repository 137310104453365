import Api from "../../modules/api/Api";
import {createAction, SUBSCRIPTION} from './types';

export const fetchSubscriptionStatus = () => dispatch => {
    dispatch(createAction(SUBSCRIPTION.FETCH_SUBSCRIPTION_START));

    return Api.getSubscriptionStatus()
        .then(status => {
            dispatch(createAction(SUBSCRIPTION.FETCH_SUBSCRIPTION_SUCCESS, {
                subscribed: status.subscribed
            }));
        })
        .catch(() => {
            dispatch(createAction(SUBSCRIPTION.FETCH_SUBSCRIPTION_ERROR, {
                subscribed: false
            }));
        });
};
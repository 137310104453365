import React from 'react';

import styles from './FullFlexHeight.module.less';

const FullFlexHeight = ({ children }) => {
    return (
        <div className={styles.root}>
            <div>{children}</div>
        </div>
    );
};

export default FullFlexHeight;
